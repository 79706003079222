export const rules = {
  revenue: [
    {id: 1592427600001, key: 0, value: 0.0},
    {id: 1604609840982, key: 10, value: 0.5},
    {id: 1592427600002, key: 100, value: 2.0},
    {id: 1592427600003, key: 150, value: 3.0},
    {id: 1592427600004, key: 200, value: 4.0},
    {id: 1592427600005, key: 250, value: 5.0},
    {id: 1592427600006, key: 300, value: 6.0},
    {id: 1592427600007, key: 350, value: 7.0},
    {id: 1592427600008, key: 400, value: 8.0},
    {id: 1592427600009, key: 450, value: 9.0},
    {id: 1592427600010, key: 500, value: 10.0},
    {id: 1604609477445, key: 600, value: 11.0},
    {id: 1604609840881, key: 700, value: 12.0},
    {id: 1592427100001, key: 800, value: 13.0},
    {id: 1604609840883, key: 900, value: 14.0},
    {id: 1604609840882, key: 950, value: 15.0},
    {id: 1592427600012, key: 1000, value: 16.0},
  ],
  roi: [
    {id: 1592427100001, key: 0, value: 0.0},
    {id: 1604609840993, key: 50, value: 0.5},
    {id: 1592427100002, key: 100, value: 1.0},
    {id: 1592427100003, key: 120, value: 2.0},
    {id: 1592427100004, key: 130, value: 3.0},
    {id: 1592427100005, key: 140, value: 4.0},
    {id: 1592427100006, key: 150, value: 5.0},
    {id: 1592427100007, key: 160, value: 6.0},
    {id: 1592427100008, key: 170, value: 7.0},
    {id: 1592427100009, key: 180, value: 8.0},
    {id: 1592427100010, key: 190, value: 9.0},
    {id: 1592427100011, key: 200, value: 10.0},
    {id: 1592427100012, key: 250, value: 12.0},
    {id: 1592427100013, key: 300, value: 14.0},
    {id: 1592427100014, key: 350, value: 16.0},
    {id: 1592427100015, key: 400, value: 18.0},
    {id: 1592427100016, key: 450, value: 20.0},
    {id: 1592427100017, key: 500, value: 22.0},
    {id: 1592427100018, key: 550, value: 24.0},
  ],
  count: [
    {id: 1592427620001, key: 0, value: 0.0},
    {id: 1592427620002, key: 3, value: 1.0},
    {id: 1592427620003, key: 4, value: 2.0},
    {id: 1592427620004, key: 5, value: 3.0},
    {id: 1592427620005, key: 6, value: 4.0},
    {id: 1592427620006, key: 7, value: 5.0},
    {id: 1592427620007, key: 8, value: 6.0},
    {id: 1592427620008, key: 9, value: 7.0},
    {id: 1592427620009, key: 10, value: 8.0},
    {id: 1592427620010, key: 15, value: 9.0},
    {id: 1592427620011, key: 20, value: 10.0},
  ]
}

export const months = []
