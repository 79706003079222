export function bindActions(actions, dispatch) {
  return Object.entries(actions).reduce((result, [key, fn]) => {
    result[key] = (...args) => {
      const action = fn(...args);

      if (typeof action.then === 'function') {
        action.then(dispatch)
      } else if (typeof action === 'function') {
        action(dispatch)
      } else if (action.type) {
        dispatch(action)
      }

      return action
    };

    return result
  }, {})
}

export function combineReducers(reducers) {
  return function (state, action) {
    return Object.entries(reducers).reduce((newState, [key, reducer]) => {
      newState[key] = reducer(state[key], action)

      return newState
    }, {})
  }
}

// Helpers

export function getRandom(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const amountFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0
})

export const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
})

export const average = list => list.reduce((prev, curr) => prev + curr);
