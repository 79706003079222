import React, {createContext, useContext, useEffect, useState} from "react"
import useStore from "../hooks/store";

const UIContext = createContext(undefined);

const UIProvider = ({children}) => {

  const yearsAvailable = [2020, 2021, 2022, 2023]

  const {
    state,
    actions: {
      getStoredData,
      syncData,
      addMonth,
      updateMonth,
      removeMonth,
      addFlow,
      updateFlow,
      removeFlow,
    }
  } = useStore()

  const [selectedYear, setSelectedYear] = useState(2023)
  const [fetching, setFetching] = useState(false)
  const [monthId, setMonthId] = useState(null)
  const [monthsList, setMonthsList] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [cached, setCached] = useState(!!state)

  const doChangeYear = (year) => {
    const value = parseInt(year)
    setSelectedMonth(null)
    setSelectedYear(value)
  }

  const doSync = () => {
    if (fetching) return
    setFetching(true)
    setSelectedMonth(null)
    setMonthId(null)
    syncData(selectedYear)
    setCached(true)
  }

  const doAddMonth = (newMonth) => {
    addMonth(newMonth)
  }

  const doUpdateMonth = (monthData) => {
    updateMonth(monthData)
  }

  const doRemoveMonth = (monthId) => {
    removeMonth(monthId)
  }

  const doAddFlow = (monthId, flowData) => {
    addFlow(monthId, flowData)
  }

  const doUpdateFlow = (monthId, flowData) => {
    updateFlow(monthId, flowData)
  }

  const doRemoveFlow = (monthId, flowId) => {
    removeFlow(monthId, flowId)
  }

  useEffect(() => {
    if (selectedYear) {
      setFetching(true)
      getStoredData(selectedYear)
    }
  }, [selectedYear])

  useEffect(() => {
    if (state.computedData.length) {
      let yearData = state.computedData.find(item => item.year === selectedYear)
      setMonthsList(yearData.months)
      setFetching(false)
    }
  }, [state.computedData])

  useEffect(() => {
    setSelectedMonth(monthsList.find(month => month.monthId === monthId))
  }, [monthId])

  let store = {
    yearsAvailable,
    selectedYear,
    fetching,
    monthId,
    selectedMonth,
    monthsList,
    doChangeYear,
    doSync,
    setMonthId,
    cached
  }

  return <UIContext.Provider value={store}>{children}</UIContext.Provider>
}

const useUIStore = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUIStore must be used within a UIProvider.`);
  }
  return context;
};

export {UIProvider, useUIStore}
