import React, {useEffect, useState} from 'react'
import {
  Box,
  RadioButtonGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  ThemeContext,
} from 'grommet'
import {amountFormatter, percentFormatter} from '../store/utils'
import {useUIStore} from "../store/UIContext";

const customTheme = {
  table: {
    body: {
      border: 'horizontal',
    },
    header: {
      background: {
        color: 'light-1',
      },
    },
  },
}

// http://ferret.grommet.io/dashboard

export default function Stats() {
  const {monthsList: months} = useUIStore()

  const [flows, setFlows] = useState([])
  const [data, setData] = useState([])
  const [collection, setCollection] = useState([])

  const [options, setOptions] = useState([]);
  const [values, setValues] = useState([]);

  const params = [
    {label: 'Revenue', value: 'revenue'},
    {label: 'Cost', value: 'totalCost'},
    {label: 'Gross Profit', value: 'grossProfit'},
    {label: 'ROI', value: 'roi'},
    {label: 'Bonus', value: 'bonus'},
  ]
  const [param, setParam] = useState(params[0].value);

  useEffect(() => {
    const list = []
    months.forEach(month => {
      // month.computedFlows
      month.flows.forEach(flow => {
        list.push(flow.name)
      })
    })

    // https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
    const _flows = Array.from(new Set(list))
    setFlows(_flows)
    setOptions(_flows)

    const _data = _flows.map(name => {
      let group = months.map(month => {
        // month.computedFlows
        return month.flows.find(flow => flow.name === name) || {
          monthId: month.monthId,
          profit: 0,
          grossProfit: 0,
          totalCost: 0,
          roi: 0,
          bonus: 0,
        }
      })
      return {
        name,
        group,
      }
    })

    setValues([])
    setData(_data)
    setCollection(_data)
  }, [months])

  useEffect(() => {
    if (values.length > 0) {
      setCollection(data.filter(f => values.includes(f.name)))
    } else {
      setCollection(data)
    }
  }, [values])


  const getValueColor = function (value) {
    let bgColor = 'accent-1'
    if (value === 0) {
      bgColor = 'light-2'
    } else if (value < 0) {
      bgColor = 'status-error'
    }
    return bgColor
  }

  if (!months) {
    return <>No data.</>
  }

  return (
    <ThemeContext.Extend value={customTheme}>
      <Box pad="medium" gap="medium" direction="row" align="center">
        <Box width="medium">
          <Select
            multiple
            options={options}
            value={values}
            placeholder="Choose flow"
            onChange={({value: nextValue}) => setValues(nextValue)}
            closeOnChange={false}
            onClose={() => setOptions(flows)}
            onSearch={text => {
              const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
              const exp = new RegExp(escapedText, 'i');
              setOptions(flows.filter(o => exp.test(o)))
            }}
            clear
          />
        </Box>
        <Box>
          <RadioButtonGroup
            name="radio"
            direction="row"
            options={params}
            value={param}
            onChange={(event) => setParam(event.target.value)}
          />
        </Box>
      </Box>
      <Box pad="medium">
        {months && (
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope="col">
                  <Text>Flows</Text>
                </TableCell>
                {months.map(m => (
                  <TableCell align="end" key={m.monthId} scope="col">
                    <Text>{m.title}</Text>
                  </TableCell>
                ))}
                <TableCell align="end" scope="col">
                  <Text weight="bold">Total</Text>
                </TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {collection?.map(row => {
                let rowTotal = row.group?.reduce((sum, item) => sum + item[param] || 0, 0)
                rowTotal = amountFormatter.format(rowTotal)
                if (param === "roi") {
                  rowTotal = rowTotal / row.group?.length
                  rowTotal = percentFormatter.format(rowTotal)
                }
                return (
                  <TableRow key={row.name}>
                    <TableCell key="flow" scope="col">
                      <Text>{row.name}</Text>
                    </TableCell>
                    {row.group?.map((g, index) => {
                      const value = g[param] || 0
                      const prevValue = index > 0 && row.group[index - 1][param]
                      const change = value && prevValue ? (value - prevValue) / prevValue : 0
                      const formatted = param === "roi" ? percentFormatter.format(value) : amountFormatter.format(value)
                      const colColor = getValueColor(change)
                      return (
                        <TableCell align="end" scope="col" key={g.monthId}>
                          {value != 0 ? (
                            <Box justify="end">
                              <Text>
                                {formatted}
                              </Text>
                              {change != 0 && (
                                <Box alignSelf="end" background={colColor} pad="xxsmall" round>
                                  <Text size="xsmall">
                                    {percentFormatter.format(change)}
                                  </Text>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <Box justify="end">
                              <Box background={colColor} pad="xxsmall"/>
                            </Box>
                          )}
                        </TableCell>
                      )
                    })}
                    <TableCell align="end" scope="col">
                      <Box background={getValueColor(rowTotal)} pad="xxsmall">
                        <Text weight="bold">{rowTotal}</Text>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </ThemeContext.Extend>
  )
}
