import React from 'react'
import {Box, Tab, Tabs} from "grommet";
import {useUIStore} from "../store/UIContext";
import MonthsList from "../components/Content/MonthsList";
import FlowsList from "../components/Content/FlowsList";

const FlowsTableMemo = (props) => {
  return (<FlowsList {...props} />)
}

export default function Dashboard() {
  const {
    fetching,
    selectedMonth,
    monthsList,
    setMonthId
  } = useUIStore()

  if (!monthsList) {
    return <>No data.</>
  }

  return (
    <Box direction="column" gap="none" fill>
      <Tabs pad={{horizontal: 'small', vertical: 'xsmall'}} alignSelf="start">
        <Tab
          title="Summary"
          onClickCapture={() => setMonthId(null)}
        />
        {monthsList.map(month => (
          <Tab
            key={month.monthId}
            title={month.title}
            onClickCapture={() => setMonthId(month.monthId)}
          />
        ))}
      </Tabs>
      <Box fill gap="small" pad="medium">
        {selectedMonth ?
          <FlowsTableMemo
            month={selectedMonth}
            // onRemove={handlerRemoveFlow}
            // onCreate={datum => handlerAddFlow(monthId, datum)}
            // onUpdate={datum => handlerUpdateFlow(monthId, datum)}
          />
          :
          <MonthsList
            months={monthsList}
            fetching={fetching}
            // onCreate={datum => handlerAddMonth(datum)}
            // onUpdate={datum => handlerUpdateMonth(datum)}
            // onRemove={handlerRemoveMonth}
          />
        }
      </Box>
      {/*<Grid
          fill
          areas={[
            {name: 'sidebar', start: [0, 0], end: [0, 0]},
            {name: 'main', start: [1, 0], end: [1, 0]},
          ]}
          rows={['flex']}
          columns={['medium', 'flex']}
        >
          <Sidebar gridArea="sidebar"/>
          <Content gridArea="main" justify="center" align="center"/>
        </Grid>*/}
    </Box>
  );
}
