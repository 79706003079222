import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter} from "react-router-dom"
import {actions, initialState, Provider, reducer} from './store'

import App from "./App"
import {UIProvider} from "./store/UIContext";

ReactDOM.render(
  <React.StrictMode>
    <Provider initialState={initialState} reducer={reducer} actions={actions}>
      <BrowserRouter>
        <UIProvider>
          <App/>
        </UIProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)
