import axios from 'axios'
import {rules as initRules} from '../modelData'
import moment from 'moment'

// Public API

// eslint-disable-next-line
const delay = t => new Promise(resolve => setTimeout(resolve, t))

export async function getStoredData(year) {
  let rules = await getFromLocalStorage('rules', initRules)
  let data = await getFromLocalStorage('data', [])

  if (year > 0 && !data.find(y => y.year === year)) {

    // Check the right date range
    let from = `${year}-01-01`
    let to = `${year}-12-31`
    const dtTo = moment.utc(to)
    const dtNow = moment.utc()

    // Check quarter range
    // if (year >= dtNow.year() && dtTo.isAfter(dtNow)) {
    //   const qrtStart = dtNow.startOf('quarter').format("YYYY-MM-DD")
    //   const qrtEnd = dtNow.endOf('quarter').format("YYYY-MM-DD")
    //   if (moment().utc().isBetween(qrtStart, qrtEnd)) {
    //     dtNow.startOf('quarter').subtract(1, "day")
    //   } else {
    //     dtNow.subtract(1, "month")
    //     dtNow.endOf("month")
    //   }
    //
    //   to = dtNow.format("YYYY-MM-DD")
    // }

    // Check current month
    if (year >= dtNow.year() && dtTo.isAfter(dtNow)) {
      dtNow.subtract(1, "month")
      dtNow.endOf("month")
      to = dtNow.format("YYYY-MM-DD")
    }


    try {
      // TODO: Move period selector to the UI
      const response = await axios.get("/api/months", {
        params: {from, to}
      })
      data.push({
        year,
        months: response.data
      })
      await setToLocalStorage('data', data)
    } catch (error) {
      console.log('error', error);
    }
  }

  //await delay(1000)
  return {rules, data}
}

export async function syncData(year) {
  const data = await getFromLocalStorage('data', [])
  const newData = data.filter(y => y.year !== year)
  await setToLocalStorage('data', newData)
  return await getStoredData(year)
}

export async function updateRules(ruleName, ruleConditions) {
  const {rules} = await getStoredData()
  const modifiedRules = await Object.entries(rules).reduce((result, [key, value]) => {
    result[key] = key === ruleName ? ruleConditions : value
    return result
  }, {})
  await setToLocalStorage('rules', modifiedRules)
  return modifiedRules
}

export async function addMonth(monthData) {
  let {months} = await getStoredData()
  let {year, month, opex, floatCost, fixedCost} = monthData
  let title = month + '-' + year.toString().slice(2)
  let date = new Date(year, MONTHS_LIST.indexOf(month) + 1, 0)
  let newMonth = {
    ...monthData,
    monthId: Date.now(),
    year: parseInt(year) || 0,
    opex: parseFloat(opex) || 0,
    date: date.getTime(),
    floatCost: parseFloat(floatCost) || 0,
    fixedCost: parseFloat(fixedCost) || 0,
    title,
    flows: [],
  }
  let modifiedMonths = months.concat(newMonth)
  await setToLocalStorage('months', modifiedMonths)
  return modifiedMonths
}

export async function updateMonth(monthData) {
  let {months} = await getStoredData()
  let {monthId, year, month, opex, floatCost, fixedCost} = monthData
  let title = month + '-' + year.toString().slice(2)
  let date = new Date(year, MONTHS_LIST.indexOf(month), 1) //getRandomInt(1,28)
  let updatedFields = {
    year: parseInt(year) || 0,
    opex: parseFloat(opex) || 0,
    date: date.getTime(),
    floatCost: parseFloat(floatCost) || 0,
    fixedCost: parseFloat(fixedCost) || 0,
    month,
    title,
  }
  let modifiedMonths = months.map(month => {
    if (month.monthId === monthId) {
      return {
        ...month,
        ...updatedFields,
      }
    }
    return month
  })
  await setToLocalStorage('months', modifiedMonths)
  return modifiedMonths
}

export async function removeMonth(monthId) {
  let {months} = await getStoredData()
  let modifiedMonths = [...months].filter(month => month.monthId !== monthId)
  await setToLocalStorage('months', modifiedMonths)
  return modifiedMonths
}

export async function addFlow(monthId, flowData) {
  let {months} = await getStoredData()
  let modifiedMonths = months.map(month => {
    if (month.monthId === monthId) {
      let newFlow = {
        flowId: Date.now(),
        monthId: monthId,
        name: flowData.name,
        revenue: parseFloat(flowData.revenue) || 0,
        prevCost: parseFloat(flowData.prevCost) || 0,
        cost: parseFloat(flowData.cost) || 0,
        activity: parseInt(flowData.activity) || 1,
        skip: Boolean(Number(flowData.skip)) || false,
      }
      return {
        ...month,
        flows: month.flows.concat(newFlow),
      }
    }
    return month
  })
  await setToLocalStorage('months', modifiedMonths)
  return modifiedMonths
}

export async function updateFlow(monthId, flowData) {
  let {months} = await getStoredData()
  let updatedFlow = {
    name: flowData.name,
    revenue: parseFloat(flowData.revenue) || 0,
    prevCost: parseFloat(flowData.prevCost) || 0,
    cost: parseFloat(flowData.cost) || 0,
    activity: parseInt(flowData.activity) || 1,
    skip: Boolean(Number(flowData.skip)) || false,
  }
  console.log(updatedFlow)
  let modifiedMonths = months.map(month => {
    if (month.monthId === monthId) {
      let flows = month.flows.map(flow => {
        if (flow.flowId === flowData.flowId) {
          return {
            ...flow,
            ...updatedFlow,
          }
        }
        return flow
      })
      return {
        ...month,
        flows,
      }
    }
    return month
  })
  await setToLocalStorage('months', modifiedMonths)
  return modifiedMonths
}

export async function removeFlow(monthId, flowId) {
  let {months} = await getStoredData()
  let modifiedMonths = await months.map(month => {
    if (month.monthId === monthId) {
      let flows = month.flows.filter(item => item.flowId !== flowId)
      return {
        ...month,
        flows,
      }
    }
    return month
  })
  await setToLocalStorage('months', modifiedMonths)
  return modifiedMonths
}


// TODO: Unused
// TODO: We need calculate only by client side using raw data
export async function getMonths(arr = []) {
  /*let monthList = arr && arr.length ? arr : initMonths
  let res = monthList.map(month => {
    let flows = month.flows.map(flow => calculateFlow(flow))
    return calculateMonth({
      ...month,
      flows,
    })
  })
  return res*/
}


// Private methods

async function getFromLocalStorage(key, initialValue) {
  let res = await window.localStorage.getItem(key)
  return res ? JSON.parse(res) : initialValue
}

async function setToLocalStorage(key, value) {
  await window.localStorage.setItem(key, JSON.stringify(value))
}

// eslint-disable-next-line
function sortMonths(list) {
  let sorted = list.sort((a, b) => a.date - b.date)
  return sorted
}

// eslint-disable-next-line
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

const MONTHS_LIST = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
