import React, {useMemo, useState} from 'react'
import {amountFormatter, percentFormatter} from '../../store/utils'
import {Box, Button, Collapsible, DataTable, DropButton, Grid, Meter, Tab, Tabs, Text} from 'grommet'
import {CaretUpFill} from 'grommet-icons'
import {ColumnDropButton} from './ColumnDropButton'


export default function FlowsList(props) {
  const month = useMemo(() => props.month, [props.month])
  const [open, setOpen] = useState(false)
  //const [currentFlow, setCurrentFlow] = useEdited()

  const allOutsiders = month.computedOutsiders
  const thisMonthOutsiders = allOutsiders.filter(flow => flow.monthId === month.monthId)
  const writeOffs = month.writeoffFlows

  // const handlerStartEdit = flow => {
  //   setCurrentFlow(flow)
  // }

  // const handlerCancelEdit = () => {
  //   setCurrentFlow(null)
  // }

  const groupColumns = [
    {
      property: 'name',
      header: 'Flow',
      footer: 'Total',
    },
    {
      property: 'revenue',
      header: 'Revenue',
      render: datum => amountFormatter.format(datum.revenue),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'dailyRevenue',
      header: 'Daily',
      render: datum => {
        if (datum.name === 'Total') return null
        return (
          <Text size="small">{amountFormatter.format(datum.dailyRevenue)}</Text>
        )
      },
      sortable: false,
      align: 'end',
    },
    {
      property: 'totalCost',
      header: 'Cost',
      render: datum => amountFormatter.format(datum.totalCost),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'grossProfit',
      header: 'Profit',
      render: datum => amountFormatter.format(datum.grossProfit),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'roi',
      header: 'ROI',
      render: datum => percentFormatter.format(datum.roi),
      align: 'end',
    },
  ]

  const flowsColumns = [
    {
      property: 'name',
      header: <Text weight="bold">Flow</Text>,
      render: datum => (
        <Box direction="row">
          {datum.flows ?
            <DropButton
              label={datum.name}
              dropProps={{align: {left: 'right', bottom: 'bottom'}, margin: {left: 'small'}}}
              dropContent={
                <Box pad="small">
                  <DataTable
                    columns={groupColumns}
                    data={datum.flows}
                    primaryKey="name"
                    sort={{
                      direction: 'desc',
                      property: 'roi',
                    }}
                    fill
                  />
                </Box>
              }
            />
            :
            datum.isBoosted ?
              <Box align="center" direction="row">
                <Text color="brand" weight="bold">{datum.name}</Text>
                <CaretUpFill color="brand"/>
              </Box>
              :
              <Text weight="bold">{datum.name}</Text>
          }
        </Box>
      ),
      footer: 'Total',
    },
    {
      property: 'revenue',
      header: 'Revenue',
      render: datum => amountFormatter.format(datum.revenue),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'dailyRevenue',
      header: 'Daily',
      render: datum => {
        if (datum.name === 'Total') return null
        return (
          <Text size="small">{amountFormatter.format(datum.dailyRevenue)}</Text>
        )
      },
      sortable: false,
      align: 'end',
    },
    {
      property: 'totalCost',
      header: 'Cost',
      render: datum => {
        if (datum.name === 'Total') return amountFormatter.format(datum.totalCost)
        return <ColumnDropButton list={datum.costs} value={datum.totalCost}/>
      },
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'grossProfit',
      header: 'Profit',
      render: datum => amountFormatter.format(datum.grossProfit),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'activity',
      header: 'Running',
      render: datum => {
        if (datum.name === 'Total') return null
        return (
          <Text>{datum.activity} <Text color="dark-4" size="xsmall">{month.maxActivity}</Text></Text>
        )
      },
      sortable: false,
      align: 'end',
    },
    {
      property: 'roi',
      header: 'ROI',
      render: datum => {
        //if (datum.name === 'Total') return null
        return percentFormatter.format(datum.roi)
      },
      // aggregate: 'avg',
      // footer: {aggregate: true},
      align: 'end',
    },
    {
      property: 'kpi',
      header: 'KPI %',
      render: datum => {
        if (datum.name === 'Total') return null
        return (
          <Box align="end">
            {datum.kpi > 0 ?
              <>
                <Box direction="row" gap="small" align="center">
                  <Meter
                    values={[
                      {value: datum.kpis.kpi1, color: 'accent-2'},
                      {value: datum.kpis.kpi2, color: 'accent-1'},
                      {value: datum.kpis.kpi3, color: 'brand'},
                    ]}
                    max={month.maxKpi}
                    thickness="small"
                    size="xsmall"
                    background="light-4"
                  />
                  <Text weight="bold">{percentFormatter.format(datum.kpi)}</Text>
                </Box>
                <Box direction="row" gap="small" align="center">
                  <Text size="xsmall" color="dark-4">{percentFormatter.format(datum.kpis.kpi1)}</Text>
                  <Text size="xsmall" color="dark-4">{percentFormatter.format(datum.kpis.kpi2)}</Text>
                  <Text size="xsmall" color="dark-4">{percentFormatter.format(datum.kpis.kpi3)}</Text>
                </Box>
              </>
              :
              <Text weight="bold">{percentFormatter.format(datum.kpi)}</Text>
            }
          </Box>
        )
      },
      align: 'end',
    },
    {
      property: 'bonus',
      header: 'Bonus',
      render: datum => amountFormatter.format(datum.bonus),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    /*{
      property: 'action',
      header: '',
      render: ((item, index) => {
        if (item.name === 'Total') return null
        if (item.isGroup) return null
        return (
          <Menu
            key={index}
            icon={<More/>}
            hoverIndicator
            dropAlign={{'right': 'right'}}
            items={[
              {
                label: 'Edit',
                icon: <Edit/>,
                gap: 'small',
                align: 'center',
                onClick: () => handlerStartEdit(item),
              },
              {
                label: 'Delete',
                icon: <Trash/>,
                gap: 'small',
                align: 'center',
                onClick: () => props.onRemove(item.monthId, item.flowId),
              },
            ]}
          />
        )
      }),
      sortable: false,
      align: 'end',
    },*/
  ]

  const outsidersColumns = [
    {
      property: 'name',
      header: 'Flow',
      footer: 'Total',
    },
    {
      property: 'revenue',
      header: 'Revenue',
      render: datum => amountFormatter.format(datum.revenue),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'totalCost',
      header: 'Cost',
      render: datum => {
        if (datum.name === 'Total') return null
        return <ColumnDropButton list={datum.costs} value={datum.totalCost}/>
      },
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'grossProfit',
      header: 'Profit',
      render: datum => amountFormatter.format(datum.grossProfit),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    /*{
      property: 'lifetime',
      header: 'Lifetime',
      render: datum => {
        if (datum.name === 'Total') return null
        return (
          <Text color="gray">{datum.lifetime}m</Text>
        )
      },
      align: 'end',
    },*/
    {
      property: 'cumulativeLosses',
      header: 'Losses+',
      render: datum => <Box background="status-error"
                            pad="xxsmall">{amountFormatter.format(datum.cumulativeLosses)}</Box>,
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
  ]

  const writeoffsColumns = [
    {
      property: 'name',
      header: 'Flow',
      footer: 'Total',
    },
    {
      property: 'revenue',
      header: 'Revenue',
      render: datum => amountFormatter.format(datum.revenue),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'cost',
      header: 'Cost',
      render: datum => amountFormatter.format(datum.cost),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'grossProfit',
      header: 'Profit',
      render: datum => amountFormatter.format(datum.grossProfit),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
  ]

  return (
    <Box align="start" gap="medium">
      <Grid
        fill="horizontal"
        columns={{
          count: 2,
          size: 'auto',
        }}
        gap="small"
      >
        <Box width="large" direction="column" align="start">
          <Button
            onClick={() => setOpen(!open)}
            secondary
            label="Outsiders"
          />
          <Collapsible open={open}>
            <Tabs justify="start" margin={{top: 'small'}}>
              <Tab title="This month">
                {!thisMonthOutsiders.length ?
                  <Box background="accent-1" margin="small">
                    <Text margin="small">No previous data</Text>
                  </Box>
                  :
                  <DataTable
                    columns={outsidersColumns}
                    background={{'body': ['none', 'light-1'],}}
                    data={thisMonthOutsiders}
                    primaryKey="flowId"
                    sort={{direction: 'asc', property: 'grossProfit',}}
                  />
                }
              </Tab>
              <Tab title="Combined til now">
                {!allOutsiders.length ?
                  <Box background="accent-1" margin="small">
                    <Text margin="small">No previous data</Text>
                  </Box>
                  :
                  <DataTable
                    columns={outsidersColumns}
                    background={{'body': ['white', 'light-1'],}}
                    data={allOutsiders}
                    primaryKey="flowId"
                    sort={{direction: 'asc', property: 'grossProfit'}}
                  />
                }
              </Tab>
              <Tab title="Write-offs">
                {!writeOffs.length ?
                  <Box background="accent-1" margin="small">
                    <Text margin="small">No write-offs</Text>
                  </Box>
                  :
                  <DataTable
                    columns={writeoffsColumns}
                    background={{'body': ['white', 'light-1'],}}
                    data={writeOffs}
                    primaryKey="flowId"
                    sort={{direction: 'asc', property: 'grossProfit'}}
                  />
                }
              </Tab>
            </Tabs>
          </Collapsible>
        </Box>
        <Box align="end">
          {/*<FlowForm
            flow={currentFlow}
            onCreate={props.onCreate}
            onUpdate={props.onUpdate}
            onCancel={handlerCancelEdit}
          />*/}
        </Box>
      </Grid>
      {
        month && month.flows.length > 0 && (
          <Box align="start" gap="medium" fill>
            <DataTable
              background={{
                'header': 'light-2',
                'body': ['white', 'light-1'],
                'footer': 'light-2',
              }}
              columns={flowsColumns}
              data={month.flows}
              primaryKey="flowId"
              sort={{direction: 'desc', property: 'kpi'}}
              pin
              fill
            />
          </Box>
        )
      }
    </Box>
  )
}
