import {getCalculatedData} from '../modelUtils'

export default function reducer(state, action) {
  let modifiedState = {}

  switch (action.type) {

    case 'GET_STORED_DATA':
      modifiedState = action.payload
      return {
        ...modifiedState,
        computedData: getCalculatedData(modifiedState),
      }

    case 'RECALCULATE_WITH_RULES':
      modifiedState = {
        ...state,
        rules: action.payload,
      }
      return {
        ...modifiedState,
        computedData: getCalculatedData(modifiedState),
      }

    case 'RECALCULATE_WITH_MONTHS':
      modifiedState = {
        ...state,
        data: action.payload,
      }
      return {
        ...modifiedState,
        computedData: getCalculatedData(modifiedState),
      }


    // TODO: ---------------------
    // TODO: Old approach
    case 'UPDATE_RULE':
      modifiedState = {
        ...state,
        rules: Object.entries(state.rules).reduce((result, [key, value]) => {
          result[key] = key === action.payload.rule ? action.payload.conditions : value
          return result
        }, {}),
      }
      return {
        ...modifiedState,
        data: getCalculatedData(modifiedState),
      }


    // TODO: Old approach
    case 'ADD_MONTH':
      return {
        ...state,
        months: state.months.concat(action.payload),
      }


    // TODO: Old approach
    case 'REMOVE_MONTH':
      return {
        ...state,
        months: [...state.months].filter(month => month.monthId !== action.payload),
      }

    // TODO: Old approach
    case 'ADD_FLOW':
      modifiedState = {
        ...state,
        months: state.months.map(month => {
          if (month.monthId === action.payload.monthId) {
            return {
              ...month,
              flows: month.flows.concat(action.payload),
            }
          }
          return month
        }),
      }
      return {
        ...modifiedState,
        months: getCalculatedData(modifiedState),
      }

    // TODO: Old approach
    case 'REMOVE_FLOW':
      modifiedState = {
        ...state,
        months: state.months.map(month => {
          if (month.monthId === action.payload.monthId) {
            let flows = month.flows.filter(item => item.flowId !== action.payload.flowId)
            return {
              ...month,
              flows,
            }
          }
          return month
        }),
      }
      return {
        ...modifiedState,
        months: getCalculatedData(modifiedState),
      }


    default:
      return state
  }

}
