import React from 'react'
import {Box, DataTable, DropButton, Text} from 'grommet'
import {amountFormatter} from '../../store/utils'

export const ColumnDropButton = ({list, value}) => {
  const costsColumns = [
    {
      property: 'name',
      header: 'Name',
      render: datum => <Text>{datum.name}</Text>,
      footer: 'Total',
    },
    {
      property: 'value',
      header: 'Value',
      render: datum => (
        <Text weight={datum.name === 'Total' ? 'bold' : 'normal'}>
          {amountFormatter.format(datum.value)}
        </Text>
      ),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
  ]

  return (
    <Box direction="row">
      <DropButton
        size="small"
        label={amountFormatter.format(value)}
        dropProps={{align: {left: 'right', bottom: 'bottom'}, margin: {left: 'small'}}}
        dropContent={
          <Box pad="small" background="dark-1">
            <DataTable
              columns={costsColumns}
              pad="xsmall"
              data={list}
              fill
            />
          </Box>
        }
      />
    </Box>
  )
}