import React from 'react'
import {Anchor, Box, Button, Grommet, Header, Main, Select, Spinner, Text} from 'grommet'
import {grommet} from 'grommet/themes'
import {generate} from 'grommet/themes/base'
import {deepMerge} from 'grommet/utils'
import {Download, Sync} from "grommet-icons"
import {NavLink, Outlet, Route, Routes, useMatch, useResolvedPath} from "react-router-dom";
import {useUIStore} from "./store/UIContext";
import Dashboard from "./pages/Dashboard";
import Stats from "./pages/Stats";

const ExportLink = (state) => {
  // TODO: Export function
  return (
    <Anchor
      reverse
      size="small"
      icon={<Download size="small"/>}
      label="JSON"
      href={`data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(state)
      )}`}
      download={"kpimodel" + new Date().getTime() + ".json"}
    />
  )
}

export default function App() {
  const theme = deepMerge(generate(20), grommet)

  return (
    <Grommet full theme={theme}>
      <Routes>
        <Route path="/" element={<Layout/>}>
          <Route index element={<Dashboard/>}/>
          <Route path="/stats" element={<Stats/>}/>
        </Route>
      </Routes>
    </Grommet>
  );
}

function Layout() {
  const {
    yearsAvailable,
    selectedYear,
    fetching,
    cached,
    doChangeYear,
    doSync,
  } = useUIStore()

  if (!cached) {
    return (
      <Box fill align="center">
        <Text>Loading...</Text>
      </Box>
    )
  }

  return (
    <>
      <Header
        direction="row"
        pad="medium"
        gap="medium"
        align="start"
        justify="between"
      >
        <Select
          disabled={fetching}
          size="medium"
          options={yearsAvailable}
          value={selectedYear}
          placeholder="Select a year"
          onChange={({value}) => doChangeYear(value)}
        />
        <Button
          icon={fetching ? <Spinner/> : <Sync/>}
          label={fetching ? "Fetching..." : "Sync sata"}
          onClick={doSync}
        />
      </Header>
      <Header background="brand" justify="start" gap="none">
        <CustomLink to="/">Dashboard</CustomLink>
        <CustomLink to="/stats">Flows</CustomLink>
      </Header>

      <Outlet/>
    </>
  )
}

function CustomLink({children, to, ...props}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({path: resolved.pathname, end: true});

  return (
    <NavLink
      style={{color: "inherit"}}
      to={to}
      {...props}
    >
      <Button hoverIndicator active={match && true}>
        <Box pad={{horizontal: 'medium', vertical: 'small'}}>
          <Text>{children}</Text>
        </Box>
      </Button>
    </NavLink>
  );
}
