import React, {useEffect, useState} from 'react'
//import useEdited from '../../hooks/edited'
import {amountFormatter, percentFormatter} from '../../store/utils'
import {Box, DataTable, Meter, Spinner, Text} from 'grommet'
import {ColumnDropButton} from "./ColumnDropButton";
//import MonthForm from './MonthForm'
//import ConfirmModal from '../ConfirmModal'

const LoadingPlaceholder = ({loading}) => {
  return loading && (
    <Box
      fill
      align="center"
      justify="center"
      direction="row"
      pad="large"
      gap="small"
      background={{color: 'background-front', opacity: 'strong'}}
    >
      <Spinner/>
      <Text weight="bold">Loading ...</Text>
    </Box>
  )
}

export default function MonthsList({months, ...props}) {
  //const [currentMonth, setCurrentMonth] = useEdited(undefined)
  //const [confirmModal, setConfirmModal] = useState({opened: false})=
  const [maxFlows, setMaxFlows] = useState(0)

  useEffect(() => {
    setMaxFlows(Math.max.apply(Math, months.map(month => month.count)))
  }, [months])

  // const handlerStartEdit = month => {
  //   setCurrentMonth(month)
  // }

  // const handlerCancelEdit = () => {
  //   setCurrentMonth(undefined)
  // }

  // const handlerStartRemove = (month) => {
  //   setConfirmModal({opened: true, title: month.title, monthId: month.monthId})
  // }

  // const handlerConfirmRemove = (isConfirm) => {
  //   if (isConfirm) props.onRemove(confirmModal.monthId)
  //   setConfirmModal({opened: false})
  // }

  // TODO: Global model settings
  // const MAX_MONTHS = 12
  const MIN_MARGIN = 0.35

  // TODO: Make as utils
  //const isMaxMonths = useMemo(() => months.length === MAX_MONTHS, [months])

  const [sort, setSort] = useState({
    property: 'date',
    direction: 'desc',
  })

  // TODO: Refactor this
  const columns = [
    {
      property: 'date',
      header: <Text weight="bold">Month</Text>,
      render: datum => {
        let title = datum.date === 'Total' ? datum.date : datum.title
        return <Text weight="bold">{title}</Text>
      },
      footer: 'Total',
    },
    {
      property: 'revenue',
      header: 'Revenue',
      render: datum => amountFormatter.format(datum.revenue),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'cost',
      header: 'Cost',
      render: datum => {
        if (datum.date === 'Total') return amountFormatter.format(datum.cost)
        return <ColumnDropButton list={datum.costs} value={datum.cost}/>
      },
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'grossProfit',
      header: 'GP',
      render: datum => amountFormatter.format(datum.grossProfit),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'roi',
      header: 'ROI',
      render: datum => percentFormatter.format(datum.roi),
      align: 'end',
      aggregate: 'avg',
      footer: {aggregate: true},
    },
    {
      property: 'count',
      header: 'Flows',
      // render: datum => <Text>{amountFormatter.format(datum.count)}</Text>,
      render: datum => {
        if (datum.name === 'Total') return null
        return (
          <Box align="end">
            {datum.count > 0 ?
              <>
                <Box direction="row" gap="small" align="center">
                  <Meter
                    values={[
                      {value: datum.count, color: 'brand'},
                    ]}
                    max={maxFlows}
                    thickness="small"
                    size="xsmall"
                    background="light-4"
                  />
                  <Text weight="bold">{amountFormatter.format(datum.count)}</Text>
                </Box>
              </>
              :
              <Text weight="bold">{amountFormatter.format(datum.count)}</Text>
            }
          </Box>
        )
      },
      align: 'end',
      aggregate: 'max',
      footer: {aggregate: true},
    },
    {
      property: 'kpi',
      header: 'KPI %',
      render: datum => <Text weight="bold">{percentFormatter.format(datum.kpi)}</Text>,
      align: 'end',
      aggregate: 'avg',
      footer: {aggregate: true},
    },
    {
      property: 'deduction',
      header: 'Deduction',
      render: datum => percentFormatter.format(datum.deduction),
      align: 'end',
    },
    {
      property: 'bonus',
      header: 'Bonus',
      render: datum => amountFormatter.format(datum.bonus),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'bonusByRevenue',
      header: 'Bonus by Rev %',
      render: datum => percentFormatter.format(datum.bonusByRevenue),
      align: 'end',
      aggregate: 'avg',
      footer: {aggregate: true},
    },
    {
      property: 'opex',
      header: 'OPEX',
      render: datum => amountFormatter.format(datum.opex),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'totalCost',
      header: 'Total Cost',
      render: datum => amountFormatter.format(datum.totalCost),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'netIncome',
      header: 'Net Income',
      render: datum => amountFormatter.format(datum.netIncome),
      align: 'end',
      aggregate: 'sum',
      footer: {aggregate: true},
    },
    {
      property: 'netMargin',
      header: 'Net Margin',
      render: datum => {
        let value = datum.date === 'Total' ? datum.netIncome / datum.revenue : datum.netMargin
        let netMargin = percentFormatter.format(value)
        let bgColor = 'accent-1'
        if (value > 0.01 && value <= MIN_MARGIN) {
          bgColor = 'status-warning'
        } else if (value < 0) {
          bgColor = 'status-error'
        }
        return (
          <Box background={bgColor} pad="xxsmall">{netMargin}</Box>
        )
      },
      align: 'end',
      aggregate: 'avg',
      footer: {aggregate: true},
    },
    /*{
      property: 'action',
      header: '',
      render: ((item, index) => {
        if (item.date === 'Total') return null
        return (
          <Menu
            key={index}
            icon={<More/>}
            hoverIndicator
            dropAlign={{'right': 'right'}}
            items={[
              {
                label: 'Edit',
                icon: <Edit/>,
                gap: 'small',
                align: 'center',
                onClick: () => handlerStartEdit(item),
              },
              {
                label: 'Delete',
                icon: <Trash/>,
                gap: 'small',
                align: 'center',
                onClick: () => handlerStartRemove(item),
              },
            ]}
          />
        )
      }),
      sortable: false,
      align: 'end',
    },*/
  ]

  return (
    <Box direction="column" align="start" gap="medium">
      {/*<MonthForm
        month={currentMonth}
        disabled={isMaxMonths}
        onCreate={props.onCreate}
        onUpdate={props.onUpdate}
        onCancel={handlerCancelEdit}
      />*/}
      {months.length > 0 && (
        <Box fill>
          <DataTable
            background={{
              'header': 'light-2',
              'body': ['white', 'light-1'],
              'footer': 'light-2',
            }}
            columns={columns}
            data={months}
            primaryKey="monthId"
            sort={sort}
            onSort={setSort}
            sortable
            pin
            fill
            // Hover Effect
            //onClickRow={event => console.log(event.datum)}
            placeholder={
              props.fetching ? <LoadingPlaceholder loading={props.fetching}/> : null
            }
          />
        </Box>
      )}
      {/*{confirmModal.opened && (
        <ConfirmModal
          title={confirmModal.title}
          onDone={handlerConfirmRemove}
        />
      )}*/}
    </Box>
  )
}
