import * as api from '../store/api'

export function getStoredData(year) {
  return api.getStoredData(year)
    .then(data => ({
        type: 'GET_STORED_DATA',
        payload: data
      }))
}

export function syncData(year) {
  return api.syncData(year)
    .then(data => ({
      type: 'GET_STORED_DATA',
      payload: data
    }))
}

export function updateRule(ruleName, ruleConditions) {
  return api.updateRules(ruleName, ruleConditions)
    .then(rules => ({
      type: 'RECALCULATE_WITH_RULES',
      payload: rules
    }))
}

export function addMonth(newMonth) {
  return api.addMonth(newMonth)
    .then(months => ({
      type: 'RECALCULATE_WITH_MONTHS',
      payload: months
    }))
}

export function updateMonth(monthData) {
  return api.updateMonth(monthData)
    .then(months => ({
      type: 'RECALCULATE_WITH_MONTHS',
      payload: months
    }))
}

export function removeMonth(monthId) {
  return api.removeMonth(monthId)
    .then(months => ({
      type: 'RECALCULATE_WITH_MONTHS',
      payload: months
    }))
}

export function addFlow(monthId, flowData) {
  return api.addFlow(monthId, flowData)
    .then(months => ({
      type: 'RECALCULATE_WITH_MONTHS',
      payload: months
    }))
}

export function updateFlow(monthId, flowData) {
  return api.updateFlow(monthId, flowData)
    .then(months => ({
      type: 'RECALCULATE_WITH_MONTHS',
      payload: months
    }))
}

export function removeFlow(monthId, flowId) {
  return api.removeFlow(monthId, flowId)
    .then(months => ({
      type: 'RECALCULATE_WITH_MONTHS',
      payload: months
    }))
}
