import moment from "moment/moment";

// ==========================
// Public functions

export const VALUE_TYPES = {
  NUMBER: 'number',
  PERCENT: 'percent',
}

export const entityDataModel = {
  revenue: {
    type: VALUE_TYPES.NUMBER,
    title: 'Revenue',
    value: 0,
  },
  cost: {
    type: VALUE_TYPES.NUMBER,
    title: 'Cost',
    value: 0,
  },
  grossProfit: {
    type: VALUE_TYPES.NUMBER,
    title: 'Gross Profit',
    value: 0,
  },
  roi: {
    type: VALUE_TYPES.PERCENT,
    title: 'ROI',
    value: 0,
  },
  kpi: {
    type: VALUE_TYPES.PERCENT,
    title: 'KPI',
    value: 0,
  },
  bonus: {
    type: VALUE_TYPES.NUMBER,
    title: 'Bonus',
    value: 0,
  },
  operatingIncome: {
    type: VALUE_TYPES.NUMBER,
    title: 'Operating Income',
    value: 0,
  },
  opex: {
    type: VALUE_TYPES.NUMBER,
    title: 'OPEX',
    value: 0,
  },
  netIncome: {
    type: VALUE_TYPES.NUMBER,
    title: 'Net Income',
    value: 0,
  },
  netMargin: {
    type: VALUE_TYPES.PERCENT,
    title: 'Net Margin',
    value: 0,
  },
}

// Calculate summary
// eslint-disable-next-line
function aggregateData(months) {
  let aggregatedData = aggregateDataWithModel(months, entityDataModel)
  let maxRoi = months.reduce((acc, item) => {
    return Math.max(item.roi)
  }, 0)
  return {
    ...aggregatedData,
    maxRoi,
  }
}

// Calculate whole state
// What we need to do:
// 1. Calculate based values fitted with Rules model: daily revenue & ROI
// 2. Calculate KPI1 & KPI2 bonuses for all flows
// 3. Filter profitable flows (having only KPI1 and KPI2 bonuses)
// 4. Apply KPI3 for profitable flows
// 5. Calculate other flows data: profit, net income, etc.
// 6. Calculate month's data: aggregate from flows data
// 7. Aggregate all months data

export function getCalculatedData({data, rules}) {
  // 1. Определить общий Revenue за месяц и сколько revenue пр флоу, не участвующих в затратах all geo
  // 2. Найти флоу, которые были убыточные в прошлых месяцах
  // 3. Распределить общие затраты на все флоу месяца + прошлые убытки
  // 4. Сгруппировать флоу на 3 группы:
  //    a. рабочие - удовлетворяющие всем условиям KPI
  //    b. групповые - удовлетворяющие хотя бы 1 условию KPI
  //    c. аутсайдеры (убыточные по факту, потенциально убыточные при включении общих затратах)
  // 5. Подготовить расчетную группу "рабочие флоу + групповые как 1 рабочий флоу"
  // 6. Калькуляция KPI для группы флоу из пункта 5
  // 6. Обновить общие суммы месяца (пункт 1) для расчетной группы
  // 7.

  return data.map(item => {
    let months = item.months
    let calcMonth = getCalculatedMonths({months, rules})
    return {
      ...item,
      months: calcMonth,
    }
  })
}

export function getCalculatedMonths({months, rules}) {
  let prevMonth = {}

  /*const getTotalRevenue = (months) => {
    const totalRevenue = months.reduce((prevMonth,month) => {
      const month.flows.filter(flow => !flow.skip).reduce((sum,flow) => {
        return sum + flow.revenue
      }, 0)
    },{})
    // Aggregate total revenue
    month.flows.forEach(flow => {
      calcMonth.revenue += flow.revenue || 0
      if (flow.skip) {
        calcMonth.skipped += flow.revenue || 0
      }
    })
  }

  const output = months.reduce((aggregated, month) => {
    aggregated.push({...month})
    return aggregated
  }, [])
  console.log(output)*/


  return months.map((month, index) => { //slice(0, 3).
    let computed = calculateMonth(month, rules, prevMonth, index)
    //console.log(computed)
    prevMonth = computed
    return computed
  })
}


// ==========================
// Private functions

// Calculate month
function calculateMonth(month, rules, prevMonth = {}, monthIndex = 0) {
  /*console.log("---")
  console.log(month.title)
  console.log(month.flows.reduce((sum, t) => sum + t.cost, 0))
  console.log(month.writeoffFlows.reduce((sum, t) => sum + t.cost, 0))*/
  // TODO: Remove hard-coding
  let MIN_ROI = rules.roi[1].key / 100
  let MIN_REVENUE = rules.revenue[1].key
  let DEV = 1.1 // +10%

  let options = {MIN_ROI, MIN_REVENUE, DEV}
  let date = new Date(month.date)

  let calcMonth = {
    revenue: 0,
    skipped: 0,
    cost: 0,
    bonus: 0,
    balanceAtStart: {

    },
    // Previous months/year cost
    prevCost: parseFloat(month.prevCost) || 0,
    // Bonus deduction
    deduction: parseFloat(month.deduction) || 0,
    // Direct costs
    directCost: 0,
    // Fixed costs will be divided evenly between flows
    fixedCost: parseFloat(month.fixedCost) || 0,
    // Float costs will be distributed between the flows in proportion to revenue
    floatCost: parseFloat(month.floatCost) || 0,
    count: month.flows.length || 0,
    prevMonth,
  }

  // Aggregate total revenue
  month.flows.forEach(flow => {
    calcMonth.revenue += flow.revenue || 0
    if (flow.skip) {
      calcMonth.skipped += flow.revenue || 0
    }
  })
  //console.log(calcMonth)

  // Collect all initial data for each flow in month context
  let computedFlows = month.flows.map(flow => {
    return calculateFlow(flow, calcMonth, options)
  })

  // Filter only active flows with MIN_REVENUE
  let passed = computedFlows.filter(flow => flow.isActive)

  // Filter outsiders
  let outsiders = computedFlows.filter(flow => flow.isLoss)

  // Filter only group flows
  let grouped = computedFlows.filter(flow => !flow.isActive && !flow.isLoss)

  //console.log(grouped)
  // console.log(month.title,
  //   `total: ${computedFlows.length}`,
  //   'equals: '+(computedFlows.length === (passed.length + grouped.length + outsiders.length)),
  //   `active: ${passed.length}`,
  //   `grouped: ${grouped.length}`,
  //   `outsiders: ${outsiders.length}`
  // )
  //console.log(computedFlows)

  // Combine all outsiders into the current month
  let prevMonthOutsiders = prevMonth.computedOutsiders || []
  let combinedOutsiders = outsiders.concat(prevMonthOutsiders)
  let computedOutsiders = combinedOutsiders.filter((item, index, self) =>
    index === self.findIndex(t => (
      t.name === item.name
    ))
  )
  computedOutsiders.forEach((flow, idx) => {
    let found = computedFlows.find(f => f.name === flow.name)
    if (found && found.isBoosted) {
      computedOutsiders.splice(idx, 1)
    }
    flow.lifetime++
  })

  // Add group flow
  if (grouped.length) {
    let groupFlow = createGroup(grouped, "Group flows", month, options)
    passed.push(groupFlow)
  }

  // Update aggregated month data
  calcMonth.revenue = 0 // reset monthly revenue
  calcMonth.skipped = 0
  calcMonth.count = passed.length
  passed.forEach(flow => {
    calcMonth.revenue += flow.revenue
    if (flow.skip) {
      calcMonth.skipped += flow.revenue
    }
  })

  // Compute flows KPI
  let flows = passed.map(flow => {
    let computed = calculateFlow(flow, calcMonth, options)
    return computeFlowKPI(computed, calcMonth, rules)
  })

  // Aggregate month's data
  let rois = []
  let kpis = []
  let days = []
  calcMonth.revenue = 0 // reset monthly revenue
  delete calcMonth.prevMonth
  flows.forEach(flow => {
    calcMonth.revenue += flow.revenue || 0
    calcMonth.directCost += flow.cost || 0
    calcMonth.cost += flow.totalCost || 0
    calcMonth.bonus += flow.bonus || 0
    rois.push(flow.roi)
    kpis.push(flow.kpi)
    days.push(flow.activity)
  })

  // Calculate monthly values
  // https://corporatefinanceinstitute.com/resources/knowledge/finance/profitability-ratios/
  let {revenue, cost, bonus} = {...calcMonth}
  calcMonth.grossProfit = revenue - cost
  calcMonth.netIncome = calcMonth.grossProfit - bonus - month.opex

  if (calcMonth.grossProfit) {
    calcMonth.netMargin = calcMonth.netIncome / revenue
    calcMonth.roi = calcMonth.grossProfit / cost
    calcMonth.kpi = bonus / calcMonth.grossProfit
  } else {
    calcMonth.netMargin = -1
    calcMonth.roi = 0
    calcMonth.kpi = 0
  }

  calcMonth.maxRoi = rois.length ? Math.max.apply(null, rois) : 0
  calcMonth.maxKpi = kpis.length ? Math.max.apply(null, kpis) : 0
  calcMonth.maxActivity = days.length ? Math.max.apply(null, days) : month.maxDays
  calcMonth.totalCost = cost + bonus - month.opex
  calcMonth.netRoi = calcMonth.netIncome / calcMonth.totalCost
  calcMonth.bonusByRevenue = calcMonth.bonus / calcMonth.revenue

  //let directСosts = calcMonth.cost - calcMonth.fixedCost - calcMonth.floatCost
  let costs = [
    {name: 'Direct Cost', value: calcMonth.directCost},
    //{name: 'Outsiders', value: computedOutsiders.reduce((sum, t) => sum + t.cost, 0)},
    {name: 'Fixed Cost', value: calcMonth.fixedCost},
    {name: 'Float Cost', value: calcMonth.floatCost},
  ]
  return {
    ...month,
    ...calcMonth,
    costs,
    flows,
    outsiders,
    computedFlows,
    computedOutsiders,
  }
}

// Calculate flow
function calculateFlow(flow, month, options = {}) {
  let prevProfit = 0
  let prevMonth = month.prevMonth
  if (prevMonth) {
    let found = []
    let prevFlow = {}
    if (prevMonth.computedOutsiders) {
      // Try to find prev loss
      found = prevMonth.computedOutsiders.filter(f => f.name === flow.name)
      if (found.length) {
        prevFlow = found[0]
        prevProfit = prevFlow.cumulativeGrossProfit
      }
    } else if (prevMonth.computedFlows) {
      // Try to find prev profit
      found = prevMonth.computedFlows.filter(f => f.name === flow.name)
      if (found.length) {
        prevFlow = found[0]
        prevProfit = prevFlow.cumulativeGrossProfit
      }
    }
  }

  let activity = parseInt(flow.activity)
  let lifetime = 0
  let isGroup = flow.isGroup || false
  let revenue = parseFloat(flow.revenue)
  let cost = parseFloat(flow.cost) // Math.round?
  let revenueRatio = !flow.skip && cost < revenue ? revenue / (month.revenue - month.skipped) : 0
  let dailyRevenue = revenue / activity
  let prevLosses = prevProfit < 0 ? Math.abs(prevProfit) : 0

  // Exclude fixed and float costs to check outside flows
  let fixedCost = 0
  let floatCost = 0
  let totalCost = cost + fixedCost + floatCost
  let sharedCost = month.prevCost + month.floatCost
  let grossProfit = revenue - totalCost
  let roi = totalCost !== 0 ? grossProfit / totalCost : 0
  let isLoss = roi < 0

  if (!isLoss) {
    fixedCost = month.fixedCost / month.count // Math.round?
    floatCost = sharedCost * revenueRatio // Math.round?
    totalCost = cost + fixedCost + floatCost
    grossProfit = revenue - totalCost
    roi = totalCost !== 0 ? grossProfit / totalCost : 0
  }

  // Check if passed flow cannot cover additional costs
  isLoss = roi < 0
  //if (isLoss) console.info(flow.name, roi, revenue, cost, isLoss)

  let isPassed = roi >= options.MIN_ROI && (dailyRevenue * options.DEV) >= options.MIN_REVENUE
  let isActive = roi > 0 && isPassed
  let isCostShared = fixedCost > 0 || floatCost > 0

  let cumulativeGrossProfit = prevProfit + grossProfit
  let isBoosted = prevLosses && cumulativeGrossProfit > 0
  let cumulativeLosses = isBoosted ? prevLosses : grossProfit - prevLosses
  if (isBoosted) {
    lifetime = 0
    totalCost += prevLosses
    grossProfit = revenue - totalCost
    cumulativeGrossProfit = prevProfit + grossProfit
  }

  let balance = {start: 0, end: grossProfit}
  if (prevMonth && prevMonth.computedFlows) {
    let found = prevMonth.computedFlows.find(f => f.name === flow.name)
    if (found) balance.start = found.balance.end
  }
  balance.end += balance.start

  let costs = [
    {name: 'Cost', value: cost},
    {name: 'Prev Losses', value: prevLosses},
    {name: 'Fixed Cost', value: fixedCost},
    {name: 'Float Cost', value: floatCost},
  ]

  return {
    ...flow,
    balance,
    isGroup,
    lifetime,
    revenueRatio,
    dailyRevenue,
    fixedCost,
    floatCost,
    totalCost,
    prevLosses,
    prevProfit,
    grossProfit,
    cumulativeLosses,
    cumulativeGrossProfit,
    costs,
    isPassed,
    isActive,
    isLoss,
    isBoosted,
    isCostShared,
    roi
  }
}

// Compute flow's KPI
function computeFlowKPI(flow, month, rules) {
  let kpi1 = 0
  let kpi2 = 0
  let kpi3 = 0
  let kpi = 0
  let bonus = 0
  let grossProfit = flow.grossProfit
  let deduction = month.deduction

  if (grossProfit > 0) {
    kpi1 = flow.roi > 0 ? calculateBonusRateByRule(flow.dailyRevenue, rules.revenue) : 0
    //kpi2 = kpi1 > 0 ? calculateBonusRateByRule(flow.roi * 100, rules.roi) : 0
    //kpi3 = kpi2 > 0 && kpi1 > 0 ? calculateBonusRateByRule(calcMonth.count, rules.count) : 0
    kpi2 = calculateBonusRateByRule(flow.roi * 100, rules.roi) || 0
    kpi3 = calculateBonusRateByRule(month.count, rules.count) || 0
    kpi = (kpi1 + kpi2 + kpi3)
    bonus = grossProfit * kpi * (1-deduction)
  }

  let netIncome = grossProfit - bonus

  return {
    ...flow,
    kpi,
    kpis: {kpi1, kpi2, kpi3},
    bonus,
    netIncome,
  }
}

// Create group of non-active flows
function createGroup(flows, name, month, options) {
  let days = []
  let group = {
    flowId: new Date().getTime() + Math.random(),
    monthId: month.monthId,
    name: name,
    revenue: 0,
    prevCost: 0,
    cost: 0,
    skip: false,
    isGroup: true,
    activity: month.daysCount,
  }

  // Aggregate flows into one grouped flow
  flows.forEach(flow => {
    group.revenue += flow.revenue || 0
    group.prevCost += flow.prevCost || 0
    group.cost += flow.cost || 0
    days.push(flow.activity)
  })

  let fakeMonth = {
    ...group,
    skipped: 0,
    fixedCost: 0,
    floatCost: 0,
    count: flows.length || 0
  }
  let groupFlows = flows.map(flow => {
    return calculateFlow(flow, fakeMonth, options)
  })

  group.activity = days.length ? Math.round(days.reduce((a, b) => a + b) / days.length) : month.daysCount

  return {
    ...group,
    flows: groupFlows
  }
}


// ==========================
// Utility functions
//

function calculateBonusRateByRule(input, rule) {
  return findMatch(input, rule) / 100
}

function findMatch(input, rule) {
  let keys = rule.map(item => item.key)
  let values = rule.map(item => item.value)
  let matchedIndex = keys.findIndex(match => match >= input)
  //let matchedIndex = keys.findIndex(match => input*1.1 <= match)
  return values[
    matchedIndex !== -1 ?
      matchedIndex - 1 :
      keys.length - 1 // max value
    ]
}

// eslint-disable-next-line
function aggregateDataWithModel(data, model) {
  return data.reduce((acc, item) => {
    Object.keys(item).forEach(key => {
      if (acc[key] !== undefined) {
        switch (acc[key].type) {
          case VALUE_TYPES.NUMBER:
            acc[key].value += item[key]
            break
          case VALUE_TYPES.PERCENT:
            acc[key].value = acc[key].value + item[key] / data.length
            break
          default:
            break // eslint-disable-next-line
        }
      }
    })
    return acc
  }, model)
}
